<template>
  <div>
    <header
      class="fixed top-0 z-[9999] flex h-14 w-full items-center justify-between bg-primary px-5 py-2.5 text-white"
    >
      <NuxtLink to="/">
        <img
          src="/images/flik-logo.svg"
          alt="Flik Logo"
          class="h-7 object-contain"
          height="30"
        />
      </NuxtLink>
      <NuxtLink to="/atur-order/pengirim">
        <IconsX class="h-3 w-3" />
      </NuxtLink>
    </header>
    <main
      class="flex w-full flex-col items-center justify-center gap-6 bg-[#F9FAFB] p-6 lg:overflow-visible"
    >
      <slot />
    </main>
  </div>
</template>
